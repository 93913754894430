import { GetServerSidePropsContext } from "next";
import { definitions } from "../../types/supabase";
import { addCachingHeaders } from "../../utils/addCachingHeaders";
import { getOptionsForPageRendering } from "../../utils/cms/getOptionsForPageRendering";
import xmsSupabase from "../../utils/xmsSupabase";
import Page, { PageProps } from "../cms/page/[pageId]";
import { NoCohortIdentifier } from "@/utils/featureFlags";

const Home = ({ ...rest }: PageProps) => {
  return <Page {...rest} />;
};

export default Home;

export const getServerSideProps = addCachingHeaders(
  async (context: GetServerSidePropsContext) => {
    const brandAbbreviation = context.params?.brandAbbreviation;

    const brand = xmsSupabase.ensureOne<definitions["brands"]>(
      await xmsSupabase.supabase
        .from("brands")
        .select("*")
        .eq("abbreviation", brandAbbreviation)
    );

    const marketingTrunkPage = xmsSupabase.ensureOne<{ page_id: string }>(
      await xmsSupabase.supabase
        .from("marketing_trunk_pages")
        .select("*")
        .eq("brand_id", brand.id)
        .eq("path", "home")
    );

    const opts = await getOptionsForPageRendering(
      marketingTrunkPage.page_id,
      NoCohortIdentifier
    );

    return {
      props: {
        brandAbbreviation,
        ...opts,
      },
    };
  }
);

import Script from "next/script";
import React, { useEffect, useRef } from "react";
import trackEvent from "../trackEvent";
import { definitions } from "../../types/supabase";
import Collector from "@combined-curiosity/collector-js";
import { SetFeatureFlags } from "@/types/decisions";
import { Brand } from "@prisma/client";

type TrackingParams = {
  pageId: string;
  gtmId: string;
  brand: definitions["brands"] | Brand;
  evaluatedFeatureFlagsHash?: string;
  featureFlags?: SetFeatureFlags;
};
const Tracking = ({
  pageId,
  gtmId,
  brand,
  evaluatedFeatureFlagsHash,
  featureFlags,
}: TrackingParams) => {
  const trackingInitialized = useRef(false);

  useEffect(() => {
    const isSupabaseBrand = (brand: any): brand is definitions["brands"] => {
      return brand.event_tracking_domain !== undefined;
    };

    const isPrismaBrand = (brand: any): brand is Brand => {
      return brand.eventTrackingDomain !== undefined;
    };

    if (brand.id && (isSupabaseBrand(brand) || isPrismaBrand(brand))) {
      if (!trackingInitialized.current) {
        const params = {
          organizationId: brand.id,
          endpointBaseUrl:
            (isSupabaseBrand(brand)
              ? brand.event_tracking_domain
              : brand.eventTrackingDomain) || "http://localhost:9009",
          cookieDomain:
            (isSupabaseBrand(brand)
              ? brand.cookie_tracking_domain
              : brand.cookieTrackingDomain) || "",
        };
        window.cc = window.cc || {};
        window.cc.organization_id = brand.id;
        window.cc.client_side_decision_id = evaluatedFeatureFlagsHash;
        window.cc.feature_flags = featureFlags;
        window.cc.collector_env = "cms";
        Collector.initialize(params);

        trackingInitialized.current = true;
      }
    }
  }, [brand?.id, brand, evaluatedFeatureFlagsHash, featureFlags]);

  useEffect(() => {
    window.cc = window.cc || {};
    window.cc.page_id = pageId;
    window.cc.organization_id = brand.id;
    if (pageId && brand?.id) {
      trackEvent({ action: "pageview" });
    }
  }, [pageId, brand]);

  return (
    <Script
      id="google-analytics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; j.height = 0; j.width = 0; j.alt = "GTM img"; if (i) f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${gtmId}');
`,
      }}
    />
  );
};

export default Tracking;

import { useEffect, useState } from "react";
import Tracking from "../../../components/cms/Tracking";
import { definitions } from "../../../types/supabase";
import { componentVersionMap } from "../../../components/cms/componentVersionMap";
import RenderedComponentsList from "../../../components/cms/RenderedComponentsList";
import { v4 as uuidv4 } from "uuid";
import { injectMetadataValues } from "../../../utils/cms/renderableResources";
import { getOptionsForPageRendering } from "../../../utils/cms/getOptionsForPageRendering";
import { TrackingWrapper } from "../../../components/trackEvent";
import { SetFeatureFlags } from "@/types/decisions";
import { FeatureFlagContext } from "components/FeatureFlagContext";
import { NoCohortIdentifier, fetchComputedFlags } from "@/utils/featureFlags";

export type PageProps = {
  componentsWithData: Array<definitions["components"] & { data: any }>;
  brandConfig: definitions["brand_configurations"];
  pageId: string;
  gtmId: string;
  brand: definitions["brands"];
  stripePublishableKey: string;
  apiUrl: string;
  children?: React.ReactNode;
  childrenClassName?: string;
  trackingDisabled?: boolean;
  featureFlags?: SetFeatureFlags;
  featureFlagHash?: string;
};

const Page = ({
  componentsWithData,
  brandConfig,
  pageId,
  gtmId,
  brand,
  stripePublishableKey,
  apiUrl,
  children,
  childrenClassName,
  featureFlags,
  featureFlagHash,
  trackingDisabled = false,
}: PageProps) => {
  const [clientSideId, setClientSideId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setClientSideId(uuidv4());
  }, []);

  const components = (componentsWithData || []).map((component, idx) => {
    const ComponentToRender = (componentVersionMap as any)[component.type][
      component.version
    ].component;

    const newData: any = {};
    Object.keys(component.data).forEach((key) => {
      const resources = component.data[key].rawResources;
      newData[key] = injectMetadataValues(resources);
    });

    let renderedComponent = (
      <ComponentToRender
        {...newData}
        component={component}
        key={JSON.stringify(component)}
        brand={brand}
        brandAbbreviation={brand.abbreviation}
        featureFlags={featureFlags}
        featureFlagHash={featureFlagHash}
        brandConfig={brandConfig}
        stripePublishableKey={stripePublishableKey}
        clientSideId={clientSideId}
        apiUrl={apiUrl}
        isDevelopmentMode={false}
      />
    );

    if (component.type === "Children") {
      return (
        <div key={`${component.id}-${idx}`} className={childrenClassName}>
          {children}
        </div>
      );
    }

    return renderedComponent;
  });

  return (
    <div className="flex flex-col min-h-screen justify-between overflow-x-hidden">
      {/* hide the HTML until the CSS file loads and resets `html` back to `display: block`. This stops FOUC in some browsers :\ */}
      <style key="hide-html">{`html { display: none }`}</style>
      {!trackingDisabled && (
        <Tracking
          key="tracking"
          pageId={pageId}
          gtmId={gtmId}
          brand={brand}
          evaluatedFeatureFlagsHash={featureFlagHash}
          featureFlags={featureFlags}
        />
      )}
      <TrackingWrapper>
        <FeatureFlagContext.Provider
          value={{
            featureFlags: featureFlags || {},
            evaluatedFeatureFlagsHash: featureFlagHash || "",
          }}
        >
          <RenderedComponentsList key="page" components={components} />
        </FeatureFlagContext.Provider>
      </TrackingWrapper>
    </div>
  );
};

export async function getStaticPaths() {
  let raw_paths = process.env.EXPORT_CMS_PAGE_PATHS;
  if (raw_paths !== undefined) {
    // This should only be set when we are exporting
    let paths = raw_paths?.split(",");
    return { paths, fallback: false };
  } else {
    return { paths: [], fallback: "blocking" };
  }
}

export async function getStaticProps(context: any) {
  let pageId;
  try {
    // SSR route
    const { query } = context;
    pageId = query.pageId;
  } catch (e) {
    // SSG route
    pageId = context.params.pageId;
  }
  const opts = await getOptionsForPageRendering(pageId, NoCohortIdentifier);

  const { featureFlags, evaluatedFeatureFlagsHash } =
    await fetchComputedFlags(NoCohortIdentifier);

  return {
    props: {
      stripePublishableKey: opts.stripePublishableKey,
      gtmId: opts.gtmId,
      pageId,
      componentsWithData: opts.componentsWithData,
      brandConfig: opts.brandConfig,
      brand: opts.brand,
      apiUrl: opts.apiUrl,
      featureFlags: featureFlags,
      featureFlagHash: evaluatedFeatureFlagsHash,
    },
    // We are keeping the page for 15s just in case we get multiple
    // requests, for the time being we are still serving behind
    // Cloudfront, so should only be rendering a small # of times
    revalidate: 15,
  };
}

export default Page;
